const baseURL = process.env.REACT_APP_API_BASE_URL;

export const apiRoutes = {
  login: `${baseURL}/auth/login`,
  agencies: `${baseURL}/agency`,
  users: `${baseURL}/users`,
  smsJob: `${baseURL}/sms`,
  integrations: { getAll: `${baseURL}/integrations/getAll` },
  invoices: {
    getAllPending: `${baseURL}/invoices/get-all-pending`,
    submitAutoPay: `${baseURL}/invoices/submit-auto-pay`,
  },
};
